const PARENT_ROUTE = '..';
const CURRENT_ROUTE = '.';
const SLASH = '/';
const COLON = ':';
const PASSWORD = 'password';
const LOGIN = 'login';
const ACTIVE_PARAM = '?active=';
const SUCCESS = 'success';
const VIEW = 'view';
const OVERVIEW = 'overview';
const DETAILS = 'details';
const DOCUMENTS = 'documents';
const CATALOG = 'catalog';
const RECENT_ORDER_ITEM = 'recent-order-item';
const PROJECT_CATALOGS = 'project-catalogs';
const ORDERS = 'orders';
const DELIVERIES = 'deliveries';
const TIMEBOARDS = 'timeboards';
const POSITIONS = 'positions';
const ACTION = 'action';
const PROJECTS = 'projects';
const COMPANY_ORDERS = 'company-orders';
const PROJECT_INFORMATION = 'project-information';
const ADDRESS = 'address';
const BILLING_INFORMATION = 'billing-information';
const SECTORS = 'sectors';
const COMPANY_ACCOUNT = 'company-account';
const FRAME_CONTRACTS = 'frame-contracts';
const SUPPLIERS = 'suppliers';
const SUPPLIER = 'supplier';
const AUTOMATIONS = 'automations';
const CONTRACT_CATALOGS = 'contract-catalogs';
const USER_ACCOUNT = 'user-account';
const SHIPMENTS = 'shipments';
const PERSONAL_INFO = 'personal-info';
const CREATE_USER_ACCOUNT = 'create-user-account';
const RESET_PASSWORD = 'reset-password';
const FORGOT_PASSWORD = 'forgot-password';
const JOIN_WAITLIST = 'join-waitlist';
const ACTIVATE_COMPANY_ACCOUNT = 'activate-company-account';
const PI = 'pi';
const CALENDAR = 'calendar';
const WAREHOUSE_SETTINGS = 'warehouse-settings';
const SETTINGS = 'settings';
const HELP = 'help';
const PROJECT_MEMBERS = 'project-members';
const LOCATIONS = 'locations';
const ACTIVE = 'active';
const DRAFT = 'draft';
const OPEN_REQUESTS = 'open-requests';
const WAITING_FOR_APPROVAL = 'waiting-for-approval';
const REQUESTED = 'requested';
const ASKED_FOR_CHANGE = 'asked-for-change';
const CONFIRMED = 'confirmed';
const DELIVERED = 'delivered';
const CLOSED = 'closed';
const ALL = 'all';
const MY = 'my';
const PROFORMA_INVOICES = 'proforma-invoices';
const DELIVERY_LOCATION = 'delivery-locations';
const RECEIVED_GOODS = 'received-goods';
const DELIVERY_NOTES = 'delivery-notes';
const INVOICES = 'invoices';
const EDIT = 'edit';
const SUPPLIER_TENDERS = 'supplier-tenders';
const ACTIVITY_LOGS = 'activity-logs';
const SUBCONTRACTORS = 'subcontractors';
const COWORKERS = 'coworkers';
const PROFILE = 'profile';
const MEMBERS = 'members';
const SUBSCRIPTION = 'subscription';
const COMPANY = 'company';
const SETUP = 'setup';
const SHOP = 'shop';
const PIM = 'pim';
const ANALYTICS = 'analytics';
const PRODUCT_ATTRIBUTES = 'product-attributes';
const CONNECTED = 'connected';
const CATHAGO_NETWORK = 'cathago-network';
const PRODUCTS = 'products';
const PRODUCT = 'product';
const SUMMARY = 'summary';
const SHARED_CATALOGS = 'shared-catalogs';
const SHARED_CATALOG_DETAILS = 'sc-detail';
const SHARED_CATALOG_PROJECTS = 'sc-project';
const SHARED_CATALOG_CATALOG = 'sc-catalog';
const SHARED_CATALOG_SUMMARY = 'sc-summary';
const SUPPORT = 'support';
const WORKFLOWS = 'workflows';
const ASK_FOR_CHANGE_REQUEST = 'ask-for-change-request';

// IDS
const WAITLISTID = 'waitlistId';
const COMPANYACCOUNTID = 'companyAccountId';
const USERID = 'userId';
const PROJECTID = 'projectId';
const ORDERID = 'orderId';
const DOCUMENTID = 'documentId';
const SHIPMENTID = 'shipmentId';
const TIMEBOARDID = 'timeboardId';
const POSITIONID = 'positionId';
const FRAMECONTRACTID = 'frameContractId';
const SUPPLIERACCOUNTID = 'supplierAccountId';
const NOTIFICATION = 'notifications';
const RECEIVED_GOODS_ID = 'receivedGoodsId';
const SHARED_CATALOG_ID = 'sharedCatalogId';

export class ROUTING {

    public static PARENT_ROUTE = PARENT_ROUTE;
    public static SLASH = SLASH;
    public static COLON = COLON;
    public static ACTIVE_PARAM = ACTIVE_PARAM;
    public static CURRENT_ROUTE = CURRENT_ROUTE;
    public static SUCCESS = 'success';
    public static HOME = 'home';
    public static DEFAULT = 'default';

    // AUTH
    public static AUTH = 'auth';
    public static LOGIN = LOGIN;
    public static PASSWORD = PASSWORD;
    public static LOGIN_PASSWORD = LOGIN + SLASH + PASSWORD;
    public static FORGOT_PASSWORD = FORGOT_PASSWORD;
    public static FORGOT_PASSWORD_SUCCESS = FORGOT_PASSWORD + SLASH + SUCCESS;
    public static ACTIVATE_COMPANY_ACCOUNT_WAITLISTID = ACTIVATE_COMPANY_ACCOUNT + SLASH + COLON + WAITLISTID;
    public static CREATE_USER_ACCOUNT = CREATE_USER_ACCOUNT;
    public static CREATE_USER_ACCOUNT_COMPANYACCOUNTID = CREATE_USER_ACCOUNT + SLASH + COLON + COMPANYACCOUNTID;
    public static CREATE_USER_ACCOUNT_COMPANYACCOUNTID_SUCCESS = CREATE_USER_ACCOUNT + SLASH + COLON + COMPANYACCOUNTID + SLASH + SUCCESS;
    public static JOIN_WAITLIST = JOIN_WAITLIST;
    public static JOIN_WAITLIST_SUCCESS = JOIN_WAITLIST + SLASH + SUCCESS;
    public static RESET_PASSWORD = RESET_PASSWORD;
    public static RESET_PASSWORD_USERID = RESET_PASSWORD + SLASH + COLON + USERID;
    public static RESET_PASSWORD_USERID_SUCCESS = RESET_PASSWORD + SLASH + COLON + USERID + SLASH + SUCCESS;

    // PROJECT INSIGHTS
    public static PROJECT_INSIGHTS = PI;
    public static PROJECTS = PROJECTS;
    public static COMPANY_ORDERS = COMPANY_ORDERS;
    public static PROJECT_INFORMATION = PROJECT_INFORMATION;
    public static ADDRESS = ADDRESS;
    public static BILLING_INFORMATION = BILLING_INFORMATION;
    public static PROJECT_ID = PROJECTID;
    public static SECTORS = SECTORS;
    public static COMPANY_ACCOUNT = COMPANY_ACCOUNT;
    public static USER_ACCOUNT = USER_ACCOUNT;
    public static PROJECT_OVERVIEW = OVERVIEW;
    public static DOCUMENTS = DOCUMENTS;
    public static CATALOG = CATALOG;
    public static RECENT_ORDER_ITEM = RECENT_ORDER_ITEM;
    public static PROJECT_CATALOGS = PROJECT_CATALOGS;
    public static ORDERS = ORDERS;
    public static DELIVERIES = DELIVERIES;
    public static CALENDAR = CALENDAR;
    public static ORDERS_ORDERID = ORDERS + SLASH + COLON + ORDERID;
    public static COLON_ORDERID = COLON + ORDERID;
    public static COLON_DOCUMENT_ID = COLON + DOCUMENTID;
    public static COLON_SHIPMENTID = COLON + SHIPMENTID;
    public static ORDERS_ORDERID_SHIPMENTS_SHIPMENTID = ORDERS + SLASH + COLON + ORDERID + SLASH + SHIPMENTS + SLASH + COLON + SHIPMENTID;
    public static RECEIVED_GOODS_ID = RECEIVED_GOODS_ID;
    public static WAREHOUSE_SETTINGS = WAREHOUSE_SETTINGS;
    public static SETTINGS = SETTINGS;
    public static HELP = HELP;
    public static PRODUCT = PRODUCT;
    public static SUMMARY = SUMMARY;
    public static COLON_SHARED_CATALOG_ID = COLON + SHARED_CATALOG_ID;

    // PROJECT OVERVIEW
    public static PROJECT_MEMBERS = PROJECT_MEMBERS;
    public static LOCATIONS = LOCATIONS;
    public static ACTIVE = ACTIVE;

    // ORDER
    public static DRAFT = DRAFT;
    public static WAITING_FOR_APPROVAL = WAITING_FOR_APPROVAL;
    public static REQUESTED = REQUESTED;
    public static ASKED_FOR_CHANGE = ASKED_FOR_CHANGE;
    public static CONFIRMED = CONFIRMED;
    public static DELIVERED = DELIVERED;
    public static CLOSED = CLOSED;
    public static ALL = ALL;
    public static MY = MY;
    public static VIEW = VIEW;
    public static OVERVIEW = OVERVIEW;
    public static DETAILS = DETAILS;
    public static PROFORMA_INVOICES = PROFORMA_INVOICES;
    public static SHIPMENTS = SHIPMENTS;
    public static DELIVERY_LOCATION = DELIVERY_LOCATION;
    public static DELIVERY_NOTES = DELIVERY_NOTES;
    public static RECEIVED_GOODS = RECEIVED_GOODS;
    public static INVOICES = INVOICES;
    public static EDIT = EDIT;
    public static ASK_FOR_CHANGE_REQUEST = ASK_FOR_CHANGE_REQUEST;

    // PROJECT PLANNING
    public static POSITIONS = POSITIONS;
    public static TIMEBOARDS = TIMEBOARDS;
    public static SUPPLIER_TENDERS = SUPPLIER_TENDERS;
    public static TIMEBOARDS_TIMEBOARDID = TIMEBOARDS + SLASH + COLON + TIMEBOARDID;
    public static POSITIONS_POSITIONID = POSITIONS + SLASH + COLON + POSITIONID;
    public static ACTION = COLON + ACTION;
    public static ACTIVITY_LOGS = ACTIVITY_LOGS;
    public static SUBCONTRACTORS = SUBCONTRACTORS;
    public static COWORKERS = COWORKERS;
    public static SUPPLIERS = SUPPLIERS;
    public static SUPPLIER = SUPPLIER;
    public static AUTOMATIONS = AUTOMATIONS;
    public static CONTRACT_CATALOGS = CONTRACT_CATALOGS;

    // COMPANY ACCOUNT SETTING
    public static PROFILE = PROFILE;
    public static MEMBERS = MEMBERS;
    public static SUBSCRIPTION = SUBSCRIPTION;
    public static COMPANY = COMPANY;
    public static SETUP = SETUP;
    public static SHOP = SHOP;

    // SECTORS
    public static FRAME_CONTRACTS = FRAME_CONTRACTS;
    public static PIM = PIM;
    public static ANALYTICS = ANALYTICS;
    public static PROJECTS_PROJECTID = PROJECTS + SLASH + COLON + PROJECTID;
    public static FRAME_CONTRACTS_FRAMECONTRACTID = FRAME_CONTRACTS + SLASH + COLON + FRAMECONTRACTID;
    public static SUPPLIERS_SUPPLIERACCOUNTID = SUPPLIERS + SLASH + COLON + SUPPLIERACCOUNTID;
    public static PRODUCT_ATTRIBUTES = PRODUCT_ATTRIBUTES;
    public static WORKFLOWS = WORKFLOWS;
    public static SHARED_CATALOG_SHARED_CATALOG_ID = SHARED_CATALOGS + SLASH + COLON + SHARED_CATALOG_ID;
    public static SHARED_CATALOG_DETAILS = SHARED_CATALOG_DETAILS;
    public static SHARED_CATALOG_PROJECTS = SHARED_CATALOG_PROJECTS;
    public static SHARED_CATALOG_CATALOG = SHARED_CATALOG_CATALOG;
    public static SHARED_CATALOG_SUMMARY = SHARED_CATALOG_SUMMARY;

    // SUPPLIERS
    public static CONNECTED = CONNECTED;
    public static CATHAGO_NETWORK = CATHAGO_NETWORK;
    public static PRODUCTS = PRODUCTS;
    public static SHARED_CATALOGS = SHARED_CATALOGS;

    // USER ACCOUNT SETTING
    public static USER_ACCOUNT_PERSONAL_INFO = SLASH + USER_ACCOUNT + SLASH + PERSONAL_INFO;
    static SUPPORT = SUPPORT;
    static NOTIFICATION = NOTIFICATION;

    // SHARED CATALOG
    public static OPEN_REQUESTS = OPEN_REQUESTS;
}
